import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Page from '../components/Page';
import { initializeApollo } from '../lib/apolloClient';
import { GET_LIVE_DATA } from '../utils/queries/live';

export default function Live({ liveFromServer, liveId, errorFromServer }) {
  return (
    <Page
      liveId={liveId}
      contentFromServer={liveFromServer}
      errorFromServer={errorFromServer}
    />
  );
}

export async function getStaticProps(ctx) {
  const liveId = 'clhq7au0m0004ho01m1g9xz7a';

  const apolloClient = initializeApollo();
  try {
    const { data } = await apolloClient.query({
      query: GET_LIVE_DATA,
      variables: { liveId }
    });

    return {
      props: {
        liveFromServer: data.live,
        liveId,
        ...(await serverSideTranslations(ctx.locale, ['common']))
      },
      revalidate: 600 // 10 minutes
    };
  } catch (error) {
    console.log('error', error?.graphQLErrors[0]);
    return {
      props: {
        errorFromServer: error?.graphQLErrors[0].extensions,
        ...(await serverSideTranslations(ctx.locale, ['common']))
      },
      revalidate: 600 // 10 minutes
    };
  }
}
